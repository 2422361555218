<template>
    <div class="search-input">
	    <input class="form-control" ref="input" type="search" :value="value" :placeholder="placeholder" @input="updateValue()">
    </div>
</template>

<script>
	export default {
		name: "SearchInput",
        methods: {
			updateValue()
			{
				this.$emit('input', this.$refs.input.value);
			},
		},
        props: {
            placeholder: String,
            value: String
        }
    }
</script>

<style lang="scss" scoped>
    .search-input{
        width: 100%;
    }
</style>