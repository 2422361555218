export default {
	methods: {
		gtm_fireBookingCancelled(bookingId, cancelledPrice)
		{
			if (this.$gtm.enabled()) {
				window.dataLayer?.push({
					event: "Booking cancelled",
					transactionId: bookingId,
					price: cancelledPrice
				});
			}
		},
		gtm_fireLogin(){
			if (this.$gtm.enabled())
			{
				window.dataLayer?.push({
					event: 'Login',
					eventCategory: 'User',
					eventAction: 'Logged in',
				});
			}
		}
	}
}


