<template>
	<div class="action-list">
		<div class="list-header">
			<div v-if="typeof title !== 'undefined'" class="list-title">
				<div class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3">
					<div class="h3 mb-0">{{title}}</div>
					<div class="btn-toolbar mb-2 mb-md-0">
						<small-button v-if="isCreatable !== false" :text='$t("terms.new")' @click.native="goTo(create)" />
					</div>
				</div>
			</div>
			<div v-if="hasSearchFilters" class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-2">
				<span class="navbar-text mr-auto">
                    <span v-if="selectFilters.length > 0 || entityFilters.length > 0" class="pr-3" >Filter by</span>
                    <select-input v-for="(filter,index) in selectFilters" :key="index" :options="filter.options" :name="filter.text" v-model="dynamicValues[filter.name]" />
					<entity-select-input v-for="(filter,index) in entityFilters" :key="index" v-model="dynamicValues[filter.name]" v-bind="filter" />
				</span>
				<div class="btn-toolbar col mb-2 mb-md-0 pr-0 pl-0">
					<search-input v-if="searchFilter !== false" v-model="dynamicValues[searchFilter.name]" :placeholder="searchFilter.text" />
				</div>
			</div>
		</div>
		<spinner v-if="isLoading"/>
		<div v-else class="box table-list-wrap">
			<div class="table-list">
				<div class="firstcol" :class="{'show-shadow':hasScrolled}"> <!-- toggle ".show-shadow" when scrolled -->
					<table>
						<thead>
							<tr>
								<th :class="firstColTdClasses">{{columns[0].header}}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(point,index) in data[page]" :key="'page-'+page+'-index-'+index" @click="handleLink(point)" :class="typeof dataRowStateFilter[index] !== 'undefined' ? dataRowStateFilter[index] : []" :ref="'firstCol'">
								<td :class="firstColTdClasses"><slot :name="columns[0].name" :data="point">{{point[columns[0].name]}}</slot></td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="wrap-nextcols" ref="nextCols">
					<div class="nextcols">
						<table>
							<thead>
								<tr>
									<th v-for="(column,index) in columns" :key='index' v-if="index !== 0" :ref="'header-'+column.name" ><span :ref="'header-text-'+column.name" class="header-value">{{column.header}}</span></th>
									<th v-if="showDelete || showCustomButtons" class="delete-col" ref="header-buttons" >
										<template v-if="showDelete && typeof deleteHeadline !== 'undefined'"><span ref="header-buttons-text" class="header-value">{{deleteHeadline}}</span></template>
									</th>
								</tr>
							</thead>
							<tbody>
								<template v-if="typeof data[page] === 'undefined' || data[page].length === 0">
									<tr>
										<td>{{$t('errors.nothingHere')}}</td>
									</tr>
								</template>
								<template v-else>
									<tr v-for="(point,index) in data[page]" :key="'page-'+page+'-index-'+index" :class="typeof dataRowStateFilter[index] !== 'undefined' ? dataRowStateFilter[index] : []" :ref="'restCol'">
										<td v-for="(col,colIndex) in columns" :key='colIndex' v-if="colIndex !== 0" @click="handleLink(point)">
											<span :ref="'value-'+col.name" class="value-display"><slot :name="col.name" :data="point">{{point[col.name]}}</slot></span>
										</td>
										<td v-if="showDelete || showCustomButtons">
											<div ref="buttons-wrapper" class="buttons text-right">
												<div v-if="showDelete && (typeof dataRowStateFilter[index] === 'undefined' || dataRowStateFilter[index].indexOf('no-delete') === -1)" class="delete" @click="deleteEntity(point)"></div>
												<template v-if="showCustomButtons">
													<slot name="buttons" :data="point"></slot>
												</template>
											</div>
											<div class="clearfix"></div>
										</td>
									</tr>
								</template>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<pagination v-if="paginated === true" v-model="page" :maxPages="maxPages" />
	</div>
</template>

<script>
	import Spinner from "@/components/loaders/Spinner";
	import {setReactiveStateValue} from "@/helperFunctions";
	import SearchInput from "@/components/inputs/SearchInput";
	import Pagination from "@/components/navigation/Pagination";
	import SelectInput from "@/components/inputs/SelectInput";
	import isGranted from "@/mixin/isGranted";
	import SmallButton from "@/components/buttons/SmallButton";
	import {mapState} from "vuex";
	import EntitySelectInput from "@/components/inputs/EntitySelectInput";
	import gtmHandler from "@/mixin/gtmHandler";
	let route = 0;
	export default {
		name: "ActionList",
		components: {EntitySelectInput, Spinner, SearchInput, Pagination, SelectInput, SmallButton},
		mixins: [isGranted,gtmHandler],
		data(){
			return {
				isLoading: false,
				searchIteration: 0,
				page: 0,
				data: {},
				total: 0,
				dynamicValues: {},
				hasScrolled: false,
				dataRowStateFilter: {},
				lastDynamicValues: {},
				firstColStyle: {},
			}
		},
		computed: {
			...mapState('concrete', {
				viewingEntity: 'viewingEntity',
			}),
			maxPages(){
				return Math.ceil(this.total / this.prPage);
			},
			selectFilters(){
				if(this.filters.length === 0)
					return [];
				
				let selects = [];
				this.filters.forEach((filter) => {
					if(filter.type === 'select')
					{
						selects.push(filter);
					}
				});
				return selects;
			},
			entityFilters(){

				if(this.filters.length === 0)
					return [];
				
				let selects = [];
				this.filters.forEach((filter) => {
					if(filter.type === 'entity')
					{
						const copy = Object.assign({},filter);
						if(typeof copy.filterProperty !== 'undefined' )
						{
							if(typeof copy.filterValue !== 'undefined' && typeof this.dynamicValues[copy.filterValue] !== 'undefined' && this.dynamicValues[copy.filterValue] !== '')
							{
								copy.filterValue = this.dynamicValues[copy.filterValue];
							}
							else
							{
								delete copy.filterProperty;
							}
					
						}
			
						selects.push(copy);
					}
				});
				return selects;
			},
			searchFilter(){
				for(let i = 0; i < this.filters.length; i++){
					if(this.filters[i].type === 'search')
						return this.filters[i];
				}
				return false;
			},
			isCreatable(){
				if(Object.keys(this.create).length === 0)
					return false;

				
				if(typeof this.create.permissions === 'undefined')
					return true;
				
				if(this.viewingEntity !== false && this.isGranted(this.create.permissions,this.viewingEntity))
					return true;
				
				return this.isGranted(this.create.permissions);
			},
			linkParameterDimensions(){
				return (typeof this.itemLink === 'object' && typeof this.itemLink.parameters === 'object') ? Object.keys(this.itemLink.parameters) : [] ;
			},
			hasSearchFilters(){
				return this.filters.length > 0
			},
			applicableRowStateFilters(){
				const accepted = ['completed','no-delete'];
				let found = [];
				Object.keys(this.rowStateFilters).forEach((key) => {
					if(accepted.indexOf(key) !== -1)
						found.push(key);
				});
				return found;
			},
			hasItemLink(){
				return typeof this.itemLink === 'object' && typeof this.itemLink.route !== 'undefined';
			}
		},
		methods: {
			determinePageStateFilters(){
				if(typeof this.data[this.page] === 'undefined' || this.data[this.page].length === 0)
					return {};

				let rowFilters = {};
				this.data[this.page].forEach((row,index) => {
					const state = this.determineRowStateFilter(row);
					if(state.length > 0)
						rowFilters[index] = state;
				});
				
				this.dataRowStateFilter = rowFilters;
			},
			determineRowStateFilter(row){
				let state = [];
				this.applicableRowStateFilters.forEach((key) => {
					const filter = this.rowStateFilters[key];
					const value = filter.value;
					const compare = (typeof filter.compare !== 'undefined') ? filter.compare : '=' ;
					const rowValue = this.determineRowStateFilterValue(row,filter.property);
					if(rowValue !== undefined)
					{
						if(compare === 'not in')
						{
							const compareValue = Array.isArray(value) ? value : [value] ;
							if(compareValue.indexOf(rowValue) === -1)
								state.push(key);
						}
						else if(compare === 'in')
						{
							const compareValue = Array.isArray(value) ? value : [value] ;
							if(compareValue.indexOf(rowValue) !== -1)
								state.push(key);
						}
						else if(compare === '!=')
						{
							if(value !== rowValue)
								state.push(key);
						}
						else
						{
							if(value === rowValue)
								state.push(key);
						}
					}
				});
				if(this.hasItemLink)
				{
					state.push('is-clickable')
				}

				return state;
			},
			determineRowStateFilterValue(row,filterProperty){
				if(filterProperty.indexOf('.') === -1)
				{
					return row[filterProperty];
				}
				else
				{
					const path = filterProperty.split('.');
					let value = row;
					for(let i = 0; i < path.length; i++)
					{
						if(typeof value[path[i]] !== 'undefined')
						{
							value = value[path[i]];
						}
						else
						{
							return undefined;
						}
					}
					return value;
				}
				return undefined;
			},
			updateColSizes(){
				this.columns.forEach((column) => {
					if(
						typeof this.$refs['value-'+column.name] !== 'undefined' &&
						typeof this.$refs['value-'+column.name][0] !== 'undefined' &&
						typeof this.$refs['header-text-'+column.name] !== 'undefined' &&
						typeof this.$refs['header-text-'+column.name][0] !== 'undefined'
						)
					{
						if(this.$refs['value-'+column.name][0].offsetWidth === 0)
						{
							setTimeout(() => {
								this.handleSingleColSize(column.name)
							},50);
						}
						else
						{
							this.handleSingleColSize(column.name)
						}
						
					}
				});
				
				if(Array.isArray(this.$refs['firstCol']))
				{
					this.$refs['firstCol'].forEach((col,i) => {
						const bounds = this.$refs['restCol'][i].getBoundingClientRect();
						this.$refs['firstCol'][i].style.cssText = 'height: '+bounds.height+'px';
					})
				}
				this.handleButtons();
			},
			handleButtons(){
				if(typeof this.$refs['buttons-wrapper'] === 'undefined' || typeof this.$refs['header-buttons'] === 'undefined')
					return;

				let max = 0;
				this.$refs['buttons-wrapper'].forEach((element) => {
					const width = element.getBoundingClientRect().width;
					if(width > max)
						max = width;
				});
				if(typeof this.$refs['header-buttons-text'] !== 'undefined' && this.$refs['header-buttons-text'].offsetWidth > max)
					max = this.$refs['header-buttons-text'].offsetWidth;

				this.$refs['header-buttons'].style.cssText = 'width: '+(max+50)+'px';
			},
			handleSingleColSize(name){
				let max = 0;
				this.$refs['value-'+name].forEach((element) => {
					const width = element.getBoundingClientRect().width;
					if(width > max)
					{
						max = width;
					}
				});
				if(this.$refs['header-text-'+name][0].offsetWidth > max)
					max = this.$refs['header-text-'+name][0].offsetWidth;
				this.$refs['header-'+name][0].style.cssText = 'width: '+(max+50)+'px';
			},
			initListeners(){
				if(typeof this.$refs['nextCols'] !== 'undefined')
				{
					this.$refs['nextCols'].addEventListener('scroll',(e) => {
						this.hasScrolled = e.currentTarget.scrollLeft !== 0
					});
				}
				if(typeof this.$refs['restCol'] !== 'undefined')
				{
					this.$refs['restCol'].forEach((el, index) => {
						el.addEventListener('mouseover',(e) => {
							this.$refs['firstCol'][index].classList.add('row-active');
						});
						el.addEventListener('mouseleave',(e) => {
							this.$refs['firstCol'][index].classList.remove('row-active');
						});
					});
					this.$refs['firstCol'].forEach((el, index) => {
						el.addEventListener('mouseover',(e) => {
							this.$refs['restCol'][index].classList.add('row-active');
						});
						el.addEventListener('mouseleave',(e) => {
							this.$refs['restCol'][index].classList.remove('row-active');
						});
					});
				}
			},
			goTo(to) {
				if(typeof to.link !== 'undefined')
				{
					window.location.href = to.link
				}
				else
				{
					this.$router.push({ name: to.route,params: {lang:this.$i18n.locale} });
				}
				
			},
			handleLink(entity){
				if(this.hasItemLink)
				{
					this.$router.push({name:this.itemLink.route,params:this.buildEntityLinkParameters(entity)});
				}
			},
			buildEntityLinkParameters(entity){
				let parameters = {
					lang:  this.$i18n.locale
				};

				for(let i = 0; i < this.linkParameterDimensions.length; i++)
				{
					const parameter = this.itemLink.parameters[this.linkParameterDimensions[i]];
					let splitParameter = parameter.split(':');

					let paramVal = entity;

					splitParameter.forEach((property) => {
						paramVal = paramVal[property];
					})

					parameters[this.linkParameterDimensions[i]] = paramVal;
				}

				return parameters;
			},
			deleteEntity(entity){
				const title = typeof this.deleteData !== 'undefined' && typeof this.deleteData.title !== 'undefined' ? this.deleteData.title : this.$t("alerts.deleteGenericTitle");
				const text = typeof this.deleteData !== 'undefined' && typeof this.deleteData.text !== 'undefined' ? this.deleteData.text : this.$t("alerts.deleteGenericEntityDescription",{id:entity.id,entity:this.$t('humanReadableEntities.'+entity['@type'])});
				const confirmBtnText = typeof this.deleteData !== 'undefined' && typeof this.deleteData.confirmBtnText !== 'undefined' ? this.deleteData.confirmBtnText : this.$t("alerts.deleteGenericConfirmBtnText");
				const cancelBtnText = typeof this.deleteData !== 'undefined' && typeof this.deleteData.cancelBtnText !== 'undefined' ? this.deleteData.cancelBtnText : this.$t("alerts.deleteGenericCancelBtnText");
				
				this.$swal.fire({
					icon: 'warning',
					title: title,
					text: text,
					showCancelButton: true,
					confirmButtonText: confirmBtnText,
					cancelButtonText: cancelBtnText,
					confirmButtonColor: "#DC3545",
				}).then((result) => {
					if (result.isConfirmed) {
						this.isLoading = true;
						if(entity['@type'] === 'Booking')
						{
							this.gtm_fireBookingCancelled(entity.id,entity.totalPrice)
						}
						else if(entity['@type'] === 'BookingSegment')
						{
							const bookingId = typeof entity.booking === 'object' ? entity.booking.id : entity.booking.replace('/api/bookings/','') ;
							this.gtm_fireBookingCancelled(bookingId,entity.price)
						}
						
						this.$store.dispatch('concrete/genericEntityRequest',{
							entity: entity,
							method: 'delete',
							skipMutations: true
						})
						.then(result => {
							
							for(let i = this.page; i <= this.maxPages; i++)
							{
								if(typeof this.data[i] !== 'undefined'){
									delete this.data[i];
								}
							}
							
							this.total = this.total - 1;
							this.fetchPage(true);
						})
						.catch(error => {
							console.log(error);
							// @todo error handling?
							this.isLoading = false;
						})
					}
				});
				
			},
			fetchPage(force){
				const page = this.page;
				force = typeof force !== 'undefined' ? force : false
				if(force || typeof this.data[page] === 'undefined')
				{
					this.isLoading = true;
					const localPage = route+0;
					this.searchIteration++;
					const localIteration = this.searchIteration+0;
					//Allow users a 200ms time to type in their next character between searches.
					setTimeout(() => {
						if(this.searchIteration === localIteration)
						{
							this.$store.dispatch('concrete/genericEntityRequest',this.buildPayload(page))
							.then(result => {
								if(typeof result['hydra:member'] !== 'undefined' && localPage === route)
								{
									this.data[page] = result['hydra:member'];
									this.total = result['hydra:allItems'];
									this.determinePageStateFilters();
								}
								else
								{
									// @todo error handling?
								}
							})
							.catch(error => {
								console.log(error);
								// @todo error handling?
							})
							.then(() => {
								this.isLoading = false;
							},() => {
								this.isLoading = false;
							})
						}
					},200)
				}
			},
			buildPayload(page){
				const parameters = {
					page: page,
					prPage: this.prPage
				};
				
				Object.keys(this.dynamicValues).forEach((key) => {
					if(this.dynamicValues[key] !== '')
					{
						parameters[key] = this.dynamicValues[key];
					}
				});
				
				Object.keys(this.staticParameters).forEach((key) => {
					parameters[key] = this.staticParameters[key];
				});
				
				return {
					entity: {
						'@type': this.entity
					},
					parameters: parameters,
					skipMutations: true,
					method: 'get'
					
				}
			},
			initDynamicValues()
			{
				this.dynamicValues = {};
				this.filters.forEach((filter) => {
					setReactiveStateValue(this.dynamicValues,filter.name,'');
				});
			},
			reset(){
				this.data = {};
				this.total = 0;
				this.page = 1;
				this.initDynamicValues();
			}
		},
		created()
		{
			this.initDynamicValues();
		},
		updated() {
			this.updateColSizes();
			this.initListeners();
		},
		watch:{
			$route(){
				route++;
				this.reset();
				this.fetchPage();
			},
			page(){
				this.fetchPage();
				this.determinePageStateFilters();
			},
			dynamicValues: {
				deep:true,
				handler: function(to,from){
					this.filters.forEach((value, index) => {
						if(typeof value.filterProperty !== 'undefined'){
							if(to[value.filterValue] !== this.lastDynamicValues[value.filterValue]){
								this.dynamicValues[value.name] = '';
							}
						}
					});
					this.lastDynamicValues = Object.assign({},to);

					this.data = {};
					this.total = 0;
					if(this.page === 1)
					{
						this.fetchPage();
					}
					else
					{
						this.page = 1;
					}
				}
			},
			reloadTrigger(){
				this.fetchPage(true);
			}
		},
		props: {
			entity: {
				type: String,
				required: true
			},
			columns: {
				type: Array,
				required: true
			},
			paginated: {
				type: Boolean,
				default: false
			},
			prPage: {
				type: Number,
				default: 10
			},
			filters: {
				type: Array,
				default: () => {
					return []
				}
			},
			title: String,
			showDelete: {
				type: Boolean,
				default: false
			},
			itemLink: {
				type: Object,
				default: () => {
					return {}
				}
			},
			create: {
				type: Object,
				default: () => {
					return {}
				}
			},
			staticParameters: {
				type: Object,
				default: () => {
					return {}
				}
			},
			showCustomButtons: {
				type: Boolean,
				default: false
			},
			reloadTrigger: {
				type: Number,
				default: 0
			},
			rowStateFilters: {
				type: Object,
				default: () => {
					return {};
				}
			},
			firstColTdClasses: {
				type: String
			},
			deleteHeadline: {
				type: String
			}
		},

	};
</script>

<style lang="scss" scoped>
	// Table
.table-list-wrap {
    .table-list {
        width: 100%;
        position: relative;
		background-color: $white;
		.nextcols,.firstcol{
			tbody {
				tr.is-clickable{
					&:hover,&.row-active {
						background: $lighterGray;
						cursor: pointer;
					}
				}
			}
		}
        table {
            width: 100%;
            thead{
                tr{
                    background: linear-gradient($gradient1,$gradient2);
                    th{
                        color: $white;
                    }
                }
            }
        }
        tr{
            position: relative;
            &:nth-child(even){
                background: $lightgrey;
            }
            &.completed{
                opacity: 0.3;
                .delete,.hide-completed{
                    display: none;
                }
            }
            th, td {
                font-weight: unset;
                padding-right: 10px;
                font-size: 14px;
                line-height: 2.4;
            }
            th {
                padding-top: 18px;
                padding-bottom: 18px;
                color: $white;
            }
            td {
                padding-top: 12px;
                padding-bottom: 12px;
            }
            .delete{
                display: inline-block;
                width: 18px;
                height: 18px;
                background: $black;
                float: right;
                border-radius: 3px;
                margin: 1px 5px 0 0;
                cursor: pointer;
                position: relative;
                &:before, &:after{
                    display: block;
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 8px;
                    height: 2px;
                    background: $white;
                    transform: translate(-50%,-50%) rotate(45deg);
                }
                &:after{
                    transform: translate(-50%,-50%) rotate(135deg);
                }
			}
        }
        .firstcol {
            background-color: $white;
            position: absolute;
            z-index: 100;
            width: $firstColWidth;
            top: 0;
            left: 0;
            &.show-shadow{
                box-shadow: 8px 0px 10px 0px rgba(0, 0, 0, 0.05);
            }
            table {
                background-color: $white;
                th,td{
                    width: 100%;
                    padding-left: $paddingLeft;
	                &.short{
		                padding-left: 20px;
	                }
                }
                td {
                    color: $black;
                }
            }
        }
        .wrap-nextcols {
            width: 100%;
            overflow: auto;
            padding-left: $firstColWidth; // This padding = firstcol width
            padding-bottom: $scrollBarSpacePadding;
            .nextcols{
                table{
                    table-layout: fixed;
                    th,td{
						.buttons{
							display: flex;
							float: left;
						}
						.clearfix{
							clear: both;
						}
	                    .value-display{
		                    white-space: nowrap;
	                    }
	                    .header-value{
		                    white-space: nowrap;
	                    }
	                    &.delete-col{
		                    width: 120px;
		                    text-align: right;
	                    }
	                    width: $nextColBaseSize;
                        padding-left: $paddingLeft;
                    }
                }
            }
        }
    }
}


</style>
