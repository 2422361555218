<template>
    <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center">
            <li class="page-item" :class="{'disabled':value === 1}">
                <a class="page-link" href="#" aria-label="Previous" @click.prevent="backClick">
                    <span aria-hidden="true">«</span>
                </a>
            </li>
            <li v-for="(item, index) in visiblePages" v-bind:key="index" class="page-item" :class="{active:item === value}" ><a class="page-link" href="#" @click.prevent="goToPage(item)">{{item}}</a></li>
            <li class="page-item" :class="{'disabled':value === maxPages}">
                <a class="page-link" href="#" aria-label="Next" @click.prevent="forwardClick">
                    <span aria-hidden="true">»</span>
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        name: "Pagination",

        props:{
            paginated: Boolean,
            value: Number,
            localPage: String,
            maxPages: Number,
        },
        methods: {
			backClick()
			{
                if(this.value != 1){
                    this.$emit('input', this.value-1)
                }
            },
            forwardClick()
            {
                if(this.value != this.maxPages){
                    this.$emit('input', this.value+1)
                }
            },
            goToPage(pageNumber){
                if(pageNumber != "..."){
                    this.$emit('input', pageNumber)
                }
            }
        },
        computed: {
            visiblePages(){
				let visible = [];
				
				const pageUpperDelimiter = this.value + 3;
				const pageLowerDelimiter = this.value - 3;
				const maxPagesDelimiter = this.maxPages - 3;
	
				for(let i = 1; i <= this.maxPages; i++)
				{
					if (i <= 3 || i > maxPagesDelimiter || (i < pageUpperDelimiter && i > pageLowerDelimiter))
					{
						visible.push(i);
					}
					else if (visible[visible.length-1] !== '...')
					{
						visible.push('...');
					}
				}
				
				return visible;
			},
        }
    }
</script>

<style lang="scss" scoped>
    /* Pagenation (move to component later) */
    .pagination{
        .page-item{
            .page-link{
                border-color: $paginationBorder;
                color: $paginationBorder;
                padding: 0.6rem 1.2rem;
                font-size: 0.875rem;
            }
            &.active{
                .page-link{
                    background-color: $active;
                    border-color: $active;
                    color: $white;
                }
            }
        }
    }
</style>
